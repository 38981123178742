import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getBoxes, getOrder, selectPack } from '../actions/PackActions'


function buildTable(boxes, parent) {
  let r = 0
  let boxes_grouped = [[]]
  boxes.map((b, index) => {
    boxes_grouped[r].push(b)
    b.num = index + 1 //nr w widoku
    b.label = b.label ? b.label : b.num.toString()
    if(b.last_in_row && index < boxes.length-1){ //koniec wiersza
      r++
      boxes_grouped[r] = []
    }
    return b
  })
  let colsum = 0
  for(let i=0; i<boxes_grouped[0].length; i++){
      colsum += boxes_grouped[0][i].width
  }
  return (
    <tbody>
      {boxes_grouped.map((row, index) => {
        let minRowspan = 9999
        let rows = ([
          <tr key={index}>
            {row.map(box => {
              if (box.height < minRowspan){
                minRowspan = box.height
              }
              return (
                <td key={box.num}
                    colSpan={box.width}
                    rowSpan={box.height}
                    data-order={box.order_id}
                    className={`${box.to_process?'':'done'}${box.errors?' errors':''}`}
                    onClick={!box.to_process || typeof parent == 'undefined'?null:parent.selectBox}
                    style={{'width': 100 / colsum * box.width + '%',
                            'height': (typeof parent == 'undefined'?5:10) * box.height + 'px'}}>
                    <div dangerouslySetInnerHTML={{__html: box.label}}/>
                </td>
              )
            })}
          </tr>
        ])
        if(minRowspan>1){
          for (var i=0; i<(minRowspan-1); i++){
              rows.push(<tr key={1000 + i}></tr>)
          }
        }
        return rows
      })}
    </tbody>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
  boxes: state.pack.boxes
})

const mapDispatchToProps = dispatch => ({
    getBoxesAction: (pack) => dispatch(getBoxes(pack)),
    getOrderAction: (kod, order_id, pack) => dispatch(getOrder(kod, order_id, pack)),
    selectPackAction: () => dispatch(selectPack())
})

class PacksComponent extends React.Component {
  componentDidMount() {
    const { getBoxesAction, authenticated, pack } = this.props;
    if (authenticated && pack) getBoxesAction(pack);
  }

  selectBox = (e) => {
    const { getOrderAction, pack } = this.props;
    const order_id = parseInt(e.currentTarget.getAttribute("data-order"));
    getOrderAction("", order_id, pack);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.boxes !== this.props.boxes) {
      setTimeout(() => {
        this.adjustFontSizeForLineBreaks(); // Upewnienie się, że działa po wyrenderowaniu tabeli
      }, 0);
    }
  }

  deselectPack = () => {
    const { selectPackAction } = this.props;
    selectPackAction();
  };

  adjustFontSizeForLineBreaks() {
    const tableSelector = ".boxy";
    const table = document.querySelector(tableSelector);
    if (!table) return;

    const cells = table.querySelectorAll("td div");
    let maxLineBreaks = 0;

    cells.forEach((cell) => {
      const content = cell.innerHTML;
      const lineBreaks = (content.match(/<p>/g) || []).length + 1;
      maxLineBreaks = Math.max(maxLineBreaks, lineBreaks);
    });

    let fontSize;
    if (maxLineBreaks > 3) {
      fontSize = "16px";
    } else if (maxLineBreaks > 1) {
      fontSize = "26px";
    } else {
      fontSize = "36px";
    }

    cells.forEach((cell) => {
      cell.style.fontSize = fontSize;
    });
  }

  render() {
    const { authenticated, boxes } = this.props;
    if (!authenticated) return <Redirect to="/login" />;
    let boxesElement = null;
    if (boxes) {
      boxesElement = buildTable(boxes, this);
    }
    return (
      <div>
        <table className="boxy">{boxesElement}</table>

        <div className="buttons_wrap">
          <button
            className="pull-left secondary"
            onClick={this.deselectPack}
          >
            {intl.get("Anuluj")}
          </button>
        </div>
      </div>
    );
  }
}

const Packs = connect(mapStateToProps, mapDispatchToProps)(PacksComponent)

export { buildTable }
export default withRouter(Packs)
